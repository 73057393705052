import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import axios from 'axios';
import SwitchButton from './components/switch/Switch';

import Header from './components/header/HeaderV2';
import Home from './pages/home/HomeV2';
import Project from './pages/proyect/Project';
import Blog from './pages/blog/Blog';

function App() {
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  return (
    <div>
      <Header/>
      <Switch>
        <Route type="all" exact path='/' component={Home} />
        <Route type="all" exact path='/project' component={Project} />
        <Route type="all" exact path='/blog' component={Blog} />
        {/* <Route type="public" exact path="/error403" component={Error403} />
        <Route type="public" path="*" component={Error404} /> */}
      </Switch>
      <div className="float">
        <SwitchButton/>
      </div>
    </div>
  );
}

export default App;
