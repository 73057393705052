import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Location from '../location/Location';

function Header() {

    const menuItems =[
        {
            'name':'Inicio', 
            'color': '#FF6EC7',
            'route': '/'
        },
        {
            'name':'Proyectos', 
            'color': '#FF6EC7',
            'route': '/project'
        },
        {
            'name':'Blog', 
            'color': '#FF6EC7',
            'route': '/blog'
        }
    ];


    // Listen the content load in Dom is finished
    document.addEventListener("DOMContentLoaded", function() {

        // Selected elements by class names
        const items = document.querySelectorAll('.nav-item');
        
        // Look for elements in items one by one
        items.forEach((item, index) => {
            item.addEventListener('click', (e) => { handleIndicator(e.target)});
            item.classList.contains('active') && handleIndicator(item);
        });
        
        // Remove previously attributes 
        function handleIndicator(el) {
        items.forEach(item => {
            item.classList.remove('active');
            item.removeAttribute('style');
        });

        // Add attributes to new element selected
        el.classList.add('active');
        el.style.color = el.getAttribute('active-color');
        }
    });


    $(function() {
        var header = $(".start-style");
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
        
            if (scroll >= 10) {
                header.removeClass('start-style').addClass("scroll-on");
            } else {
                header.removeClass("scroll-on").addClass('start-style');
            }
        });
    });		

    //Menu On Hover
    $('body').on('mouseenter mouseleave','.nav-item',function(e){
            if ($(window).width() > 750) {
                var _d=$(e.target).closest('.nav-item');_d.addClass('show');
                setTimeout(function(){
                _d[_d.is(':hover')?'addClass':'removeClass']('show');
                },1);
            }
    });	  

    return(
        <Style>
            <div className="navigation-wrap bg-theme start-header start-style">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md navbar-theme">
                                <Link className="navbar-brand" to="/">
									Bryan Rogel
                                </Link>	
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto py-4 py-md-0">
                                        {menuItems && menuItems.map((item, index)=>(
                                            <Link key={index} id={index} to={item.route}>
                                                <li className={`nav-item pl-4 pl-md-0 ml-0 ml-md-4 nav-link ${ Location(item.route) && 'active' }`} data-toggle="collapse" data-target=".navbar-collapse.show">
                                                    {item.name}
                                                </li>
                                            </Link>
                                        ))}
                                        {/* <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
                                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Options</a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="#">Options 1</a>
                                                <a className="dropdown-item" href="#">Options 2</a>
                                                <a className="dropdown-item" href="#">Options 3</a>
                                                <a className="dropdown-item" href="#">Options 4</a>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                                
                            </nav>		
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
} 

const Style = styled.nav`

/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 20px 0;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.start-header.scroll-on {
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
	padding: 10px 0;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navigation-wrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.navbar{
	padding: 0;
}
.navbar-brand{
    font-size: 20px;
	font-weight: 600;
}
.navbar-toggler {
	float: right;
	border: none;
	padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
	outline: none;
}
.navbar-theme .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 1px solid #ff6ec7;
    transition: all 300ms linear;
}
.navbar-theme .navbar-toggler-icon:after, 
.navbar-theme .navbar-toggler-icon:before{
	width: 24px;
	position: absolute;
	height: 1px;
	background-color: #ff6ec7;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
    transition: all 300ms linear;
}
.navbar-theme .navbar-toggler-icon:after{
	top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
.nav-link{
	font-weight: 500;
    transition: all 200ms linear;
}
.nav-item:hover .nav-link{
	color: #ff6ec7 !important;
}
.nav-item.active .nav-link{
	color: #ff6ec7 !important;
}
.nav-link {
	position: relative;
	padding: 5px 0 !important;
	display: inline-block;
}
.nav-item:after{
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 2px;
	content: '';
	opacity: 0;
	background-color: #ff6ec7;
	transition: all 200ms linear;
}
.nav-item:hover:after{
	opacity: 0;
}
.nav-item.active:after{
	bottom: 0;
	opacity: 1;
}
.nav-item{
	position: relative;
	transition: all 200ms linear;
}

/* #Primary style
================================================== */

.section {
    position: relative;
	width: 100%;
	display: block;
}
.full-height {
    height: 100vh;
}
.over-hide {
    overflow: hidden;
}

.nav-item .dropdown-menu {
    transform: translate3d(0, 10px, 0);
    visibility: hidden;
    opacity: 0;
	max-height: 0;
    display: block;
	padding: 0;
	margin: 0;
    transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
	max-height: 999px;
    transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
	padding: 10px!important;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: #212121;
	background-color: #fcfaff;
	border: none;
	border-radius: 3px;
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    transition: all 200ms linear;
}
.dropdown-toggle::after {
	display: none;
}

.dropdown-item {
	padding: 3px 15px;
	border-radius: 15px;
	margin: 2px 0px;
	transition: all 200ms linear;
}
.dropdown-item:hover, 
.dropdown-item:focus {
	color: #ffffff;
	font-weight: bold;
	background-color: #ff6ec7;
}

/* #Media
================================================== */

@media (max-width: 767px) { 
	h1{
		font-size: 38px;
	}
	.dropdown-toggle[aria-expanded="true"]::after{
		transform: rotate(90deg);
		opacity: 0;
	}
	.dropdown-menu {
		padding: 0 !important;
		background-color: transparent;
		box-shadow: none;
		transition: all 200ms linear;
	}
	.dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
		margin-top: 10px !important;
		margin-bottom: 20px !important;
	}
}

/* #Link to page
================================================== */

.logo {
	position: absolute;
	bottom: 30px;
	right: 30px;
	display: block;
	z-index: 100;
	transition: all 250ms linear;
}
.logo img {
	height: 26px;
	width: auto;
	display: block;
	filter: brightness(10%);
	transition: all 250ms linear;
}
`;

export default Header;