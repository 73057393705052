import React from 'react';
import styled from 'styled-components';

function Switch() {

    const DarkModeIsActive = localStorage.getItem('dark-mode') === 'true';

    // Listen the content load in Dom is finished
    document.addEventListener("DOMContentLoaded", function() {
        const btnSwitch = document.querySelector('#switch');
    
        // Add or remove the class of the dark mode
        btnSwitch.addEventListener('click', () => {
            document.body.classList.toggle('dark');
            btnSwitch.classList.toggle('active');

            // Save or remove dark mode in local storage
            if(document.body.classList.contains('dark')){
                localStorage.setItem('dark-mode', 'true');
            } else {
                localStorage.setItem('dark-mode', 'false');
            }
        });

        // Check if exist dark mode in local storage
        if(localStorage.getItem('dark-mode') === 'true'){
            document.body.classList.add('dark');
            btnSwitch.classList.add('active');
        } else {
            document.body.classList.remove('dark');
            btnSwitch.classList.remove('active');
        }
    });

    return(
        <Style>
            <div className="toggle-button-cover">
            <div className="button-cover">
                <div className="button r" id="button-1">
                <input type="checkbox" defaultChecked={DarkModeIsActive} id="switch" className="checkbox"/>
                <div className="knobs"/>
                <div className="layer"/>
                </div>
            </div>
            </div>
        </Style>
    )
}

const Style = styled.div`
margin-right: 75px;
.toggle-button-cover {
    position: relative;
}
.button-cover, .knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.button {
    position: relative;
    width: 75px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}
.button.r, .button.r .layer {
    border-radius: 100px;
}
.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}
.knobs {
    z-index: 2;
}
.layer {
    background-color: var(--black);
    transition: 0.3s ease all;
}
#button-1 .knobs:before {
    content: '☀️';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 31px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: var(--white);
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
    content: '🌙';
    left: 42px;
    background-color: var(--black);
}
#button-1 .checkbox:checked ~ .layer {
    background-color: var(--white);
}
#button-1 .knobs, #button-1 .knobs:before, #button-1 .layer {
    transition: 0.3s ease all;
}
`;

export default Switch;