import React from 'react';
import styled from 'styled-components';

import '../../assets/css/bootstrap.min.css';

function Project(){

    const tecnologies = [
        {
            name: "Axios, ",
            url: "https://www.npmjs.com/package/axios"
        },
        {
            name: "Styled Components, ",
            url: "https://styled-components.com/"
        },
        {
            name: "Redux y ",
            url: "https://react-redux.js.org/"
        },
        {
            name: "Apexcharts",
            url: "https://apexcharts.com/"
        },
    ]

    return(
        <Style>
            <div className="row" >
                <div className="border project">
                    <div className="col-xl-8 col-md-8 col-12 max-h">
                        <h1>Pokedex</h1>
                        <p>Proyecto personal creado con React JS, hace uso de tecologias como { tecnologies && tecnologies.map( (item, i) => (
                            <a href={item.url} key={i} rel="noopener noreferrer">{item.name}</a>
                        )) }.</p>
                    </div>
                    <div className="col-xl-4 col-md-4 col-12 image-container">
                        <img height="250px" className="border image" alt="proyect" src="https://i.ibb.co/q0YKY82/pokedex-image.png"/>
                    </div>
                </div>
            </div>

            
        </Style>
    );
}

const Style = styled.div`
    margin: 100px 20px 0;
    min-height: calc(100vh - 100px);
    max-width: 1366px;
    .row {
        margin: 0px;
        margin-top: 20px;
        
    }
    .border {
        display: flex;
        border-radius: 20px;
        border-width: medium !important;
    }
    .image-container{
        padding: 10px;
    }
    .image {
        width: 100%;
        object-fit: contain;
        background-color: var(--white);
    }
    .max-h {
        max-height: 250px;
        overflow: hidden;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        
    }
    h1 {
        color: var(--neo-pink);
    }
    a {
        text-decoration: none;
        color: var(--neo-pink);
    }
`;

export default Project;