import React from 'react';
import styled from 'styled-components';

import '../../assets/css/bootstrap.min.css';

function Blog(){

    return(
        <Style>
            <div className="row" >
                <div className="col-xl-12 col-md-12 col-12">
                    <h1>Próximamente</h1>
                </div>
            </div>
        </Style>
    );
}

const Style = styled.div`
    height: calc(100vh - 100px);
    max-width: 1366px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    
    h1 {
        color: #FF6EC7;
    }
`;

export default Blog;