import React from 'react';
import styled from 'styled-components';

function HomeV2() {
    return(
        <Style>
            <section>
                <div className="container">
                    <div className="row body-presentation pt-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="split-screen image-bg">
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="split-screen all-center">
                                <p>Hola! Soy Bryan Rogel, <br/> Desarrollador Web</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Style>
    )
    
}

const Style = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    p {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: var(--neo-pink);
    }
    .body-presentation {
        height: 100%;
        margin-top: 100px;
        min-height: calc(100vh - 100px);
        align-items: stretch;
    }
    .col-12 {
        padding: 20px;
    }
    .split-screen {
        width: 100%;
        height: calc(100vh - 150px);
        min-height: 300px;
    }
    .all-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-bg {
        border-radius: 60px;
        background: url("https://i.pinimg.com/originals/1d/2c/68/1d2c6830ced2412b4c4f4dee59fc6ba2.gif");
        background-repeat: no-repeat;
        /* background-size:  cover; */
        background-size: contain;
        background-position: center center;
        background-blend-mode: overlay;
    }
`;

export default HomeV2;